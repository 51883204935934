import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { useClickOutside } from '@mantine/hooks'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { CHAIN_ID, ROUTE_PATH } from '@/shared/constants'
import { IGetCollectionResponse } from '@/shared/types'
import { getChainscanUrl } from '@/shared/utils'

import { TabBar } from '../common'

export interface ICollectionDetailTabBarProps {
  collection: IGetCollectionResponse
}

export const CollectionDetailTabBar: React.FC<ICollectionDetailTabBarProps> = ({
  collection,
}: ICollectionDetailTabBarProps) => {
  const { query, pathname } = useRouter()
  const { collectionAddress } = query as { collectionAddress?: string }
  const realPathname = useMemo(
    () => pathname.replace('[collectionAddress]', collectionAddress ?? ''),
    [collectionAddress, pathname],
  )
  const { t } = useTranslation(['common', 'web-collection'])

  const tabLinks = useMemo(() => {
    if (collectionAddress === undefined) return []
    return [
      {
        to: ROUTE_PATH.COLLECTION_DETAIL.replace(':collectionAddress', collectionAddress),
        name: t('common:common.label-item'),
      },
      {
        to: ROUTE_PATH.COLLECTION_ACTIVITY.replace(':collectionAddress', collectionAddress),
        name: t('common:common.label-activity'),
      },
    ]
  }, [collectionAddress, t])

  const [openMoreMenu, setOpenMoreMenu] = useState(false)
  const openMoreMenuRef = useClickOutside(() => {
    setOpenMoreMenu(false)
  })

  useEffect(() => {
    setOpenMoreMenu(false)
  }, [pathname])

  const collectionLinksMappings = useMemo(() => {
    const makeScan = (
      chainId: string,
      collectionAddress: string,
    ): { url: string; icon: string; text: string } => {
      const titleMappings = {
        [CHAIN_ID.polygon]: `${t('common:common.collection-move-polygon-scan')}`,
        [CHAIN_ID.amoy]: `${t('common:common.collection-move-polygon-scan')}`,
        [CHAIN_ID.mumbai]: `${t('common:common.collection-move-polygon-scan')}`,
        [CHAIN_ID.ethereum]: t('web-collection:collection.open-etherscan'),
        [CHAIN_ID.sepolia]: t('web-collection:collection.open-etherscan'),
      }
      return {
        url: `${getChainscanUrl(chainId)}/address/${collectionAddress}`,
        icon: '/img/icon/etherscan.svg',
        text: titleMappings[chainId],
      }
    }
    return {
      website: {
        url: collection.website,
        icon: '/img/icon/globe.svg',
        text: t('web-collection:collection.open-official-website'),
      },
      etherscan: makeScan(collection.chainId, collection.collectionAddress),
      discord: {
        url: collection.discord,
        icon: '/img/icon/discord-light.svg',
        text: t('web-collection:collection.open-discord'),
      },
      twitter: {
        url: collection.twitter,
        icon: '/img/icon/twitter.svg',
        text: t('web-collection:collection.open-twitter'),
      },
    }
  }, [
    collection.chainId,
    collection.collectionAddress,
    collection.discord,
    collection.twitter,
    collection.website,
    t,
  ])

  const renderMoreMenu = () => {
    return (
      <div
        ref={openMoreMenuRef}
        className="absolute top-12 right-4 mt-2 w-40 bg-bgTertiary border-gray-600 rounded-lg focus:outline-none z-50"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button">
        <div className="items-center">
          <div className="lg:hidden block">
            {renderLinkButton('website')}
            {renderLinkButton('etherscan')}
            {renderLinkButton('discord')}
            {renderLinkButton('twitter')}
          </div>
          <hr className="block lg:hidden border-gray-500 border-[0.5px] mx-2" />
          <CopyToClipboard text={window.location.href}>
            <button
              className="block w-[158px] h-[37px] px-[16px] text-left text-sm font-semibold text-textPrimary bg-bgTertiary rounded-lg border-gray-600 hover:bg-bgQuarternary"
              onClick={e => {
                e.preventDefault()
                toast(`${t('common:common.toast-copied-wallet-address')}`, {
                  toastId: 'share',
                })
                setOpenMoreMenu(!openMoreMenu)
              }}>
              {t('common:common.cta-label-share')}
            </button>
          </CopyToClipboard>
        </div>
      </div>
    )
  }

  const renderLinkButton = (type: 'website' | 'etherscan' | 'discord' | 'twitter') => {
    const link = collectionLinksMappings[type]
    if (!link.url) return null
    return (
      <a
        onClick={() => {
          if (window !== undefined) window.location.href = link.url as string
        }}
        rel="noreferrer">
        <Image
          className="mx-2 cursor-pointer hidden lg:block"
          width={28}
          height={28}
          src={link.icon}
          alt="link icon"
        />
        <button className="block lg:hidden w-[158px] h-[37px] px-[16px] text-left text-sm font-semibold text-textPrimary bg-bgTertiary rounded-lg border-gray-600 hover:bg-bgQuarternary">
          {link.text}
        </button>
      </a>
    )
  }

  return (
    <>
      <TabBar
        wrapperClassNames="flex justify-between relative w-full"
        pathname={realPathname}
        links={tabLinks}>
        <div className="flex items-center">
          <div className="hidden lg:block">
            {renderLinkButton('website')}
            {renderLinkButton('etherscan')}
            {renderLinkButton('discord')}
            {renderLinkButton('twitter')}
          </div>
          <EllipsisVerticalIcon
            onClick={() => setOpenMoreMenu(!openMoreMenu)}
            className="w-[24px] lg:w-[28px] h-[24px] lg:h-[28px] cursor-pointer"
          />
          {openMoreMenu && renderMoreMenu()}
        </div>
      </TabBar>
    </>
  )
}
